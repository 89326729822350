import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ManufacProvider extends HttpRequest {
  constructor () {
    super(`${process.env.VUE_APP_MER_API}/prototypes`)
  }

  getManyManufac (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/manufacture', query)
  }

  getTracking (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/manufacture/tracking', query)
  }

  createManufacturing (ids) {
    this.setHeader(getAuthToken())
    return this.post('/manufacture', ids)
  }

  changeManufactureStatus (ids, state) {
    this.setHeader(getAuthToken())
    return this.patch('/manufacture/changes-status', { ids, state })
  }
}

export default ManufacProvider
